


.dapp-category-container{
    display: flex;
    flex: 1;
    min-width: 50%;
}

.dapp-category{
    display: flex;
    flex: 1;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: center;
    align-items: center;
    min-width: 150px;
    margin: 7px;
    padding-left: 15px;
    padding-right: 8px;
}

.dapp-category:nth-child(even) {
    margin-right: 0px;
}

.dapp-category-icon {
    font-size: 32px;
    font-size: 32px;
    line-height: 32px;
    width: 32px;
    height: 32px;
}

.dapp-category-name {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #24292E;
    padding: 8px;
}

